import type { PPPProject } from 'ppp/types'

export type ManageContentProps = {
  project: PPPProject
  managePayoutsUrl?: string
  manageIframeUrl?: string
  manageProjectDashboardUrl?: string
  sayThankYouUrl?: string
  manageNeedsUrl?: string
  manageImagesUrl?: string
  writeNewsUrl?: string
  quickAccessWirwunderUrl?: string
  quickAccessNussbaumUrl?: string
  editDescriptionUrl?: string
}

export const projectButtonTypes = {
  managePayouts: 'manage_payouts',
  editProject: 'edit_project',
  sayThankYou: 'say_thank_you',
  manageNeeds: 'manage_needs',
  writeNews: 'write_news',
  manageImages: 'manage_images',
  addIframe: 'add_iframe',
  allSettings: 'all_settings',
  editDescription: 'edit_description',
} as const

export const ItemsGetterType = {
  filtered: 'filtered',
  regular: 'regular',
} as const
