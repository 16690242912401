import { ItemsGetterType, projectButtonTypes } from './types'

const preparing = [projectButtonTypes.editDescription, projectButtonTypes.manageNeeds, projectButtonTypes.manageImages]

// Items to display when there's enough room for everything
const activeNoDonations = [
  projectButtonTypes.manageNeeds,
  projectButtonTypes.writeNews,
  projectButtonTypes.manageImages,
  projectButtonTypes.addIframe,
]
const activeWithDonations = [
  projectButtonTypes.sayThankYou,
  projectButtonTypes.manageNeeds,
  projectButtonTypes.writeNews,
  projectButtonTypes.addIframe,
]
const financedAndOpen = [
  projectButtonTypes.managePayouts,
  projectButtonTypes.sayThankYou,
  projectButtonTypes.manageNeeds,
  projectButtonTypes.writeNews,
]
const blocked = [
  projectButtonTypes.sayThankYou,
  projectButtonTypes.manageNeeds,
  projectButtonTypes.writeNews,
  projectButtonTypes.manageImages,
]

function getItemsToDisplay(status: string, hasDonations: boolean, completed?: boolean) {
  switch (true) {
    case status === 'preparing':
    case status === 'proposed':
      return preparing
    case status === 'activated' && completed:
      return financedAndOpen
    case status === 'activated' && !hasDonations:
      return activeNoDonations
    case status === 'activated' && hasDonations:
      return activeWithDonations
    case status === 'blocked':
      return blocked
    default:
      return []
  }
}

// items to display, when there would be more than 6 items to show
const activeNoDonationsFiltered = [
  projectButtonTypes.manageNeeds,
  projectButtonTypes.writeNews,
  projectButtonTypes.manageImages,
]
const activeWithDonationsFiltered = [
  projectButtonTypes.sayThankYou,
  projectButtonTypes.manageNeeds,
  projectButtonTypes.writeNews,
]
const financedAndOpenFiltered = [
  projectButtonTypes.managePayouts,
  projectButtonTypes.sayThankYou,
  projectButtonTypes.writeNews,
]
const blockedFiltered = [projectButtonTypes.sayThankYou, projectButtonTypes.writeNews, projectButtonTypes.manageImages]

function getFilteredItemsToDisplay(status: string, hasDonations: boolean, completed?: boolean) {
  switch (true) {
    case status === 'preparing':
    case status === 'proposed':
      return preparing
    case status === 'activated' && completed:
      return financedAndOpenFiltered
    case status === 'activated' && !hasDonations:
      return activeNoDonationsFiltered
    case status === 'activated' && hasDonations:
      return activeWithDonationsFiltered
    case status === 'blocked':
      return blockedFiltered
    default:
      return []
  }
}

export const getItems = {
  [ItemsGetterType.filtered]: getFilteredItemsToDisplay,
  [ItemsGetterType.regular]: getItemsToDisplay,
}
