import React, { useMemo } from 'react'
import classNames from 'classnames'
import styles from './quick_access_box.module.css'
import { AdminBox, QuickAccessButton } from 'shared'
import { ItemsGetterType, type ManageContentProps, projectButtonTypes } from './types'
import { getItems } from './config'

export const QuickAccessBox = ({
  project,
  manageIframeUrl,
  manageImagesUrl,
  manageNeedsUrl,
  manageProjectDashboardUrl,
  managePayoutsUrl,
  sayThankYouUrl,
  writeNewsUrl,
  quickAccessWirwunderUrl,
  quickAccessNussbaumUrl,
  editDescriptionUrl,
}: ManageContentProps): JSX.Element | null => {
  const hasDonations = project.donatedAmountInCents > 0
  const { status: projectStatus, completed } = project
  const bothClientsConnected =
    quickAccessWirwunderUrl && quickAccessNussbaumUrl ? ItemsGetterType.filtered : ItemsGetterType.regular
  const itemsToDisplay = useMemo(() => {
    return getItems[bothClientsConnected](projectStatus, hasDonations, completed)
  }, [bothClientsConnected, projectStatus, hasDonations, completed])

  const urlsToTypesMap = {
    [projectButtonTypes.addIframe]: manageIframeUrl,
    [projectButtonTypes.manageImages]: manageImagesUrl,
    [projectButtonTypes.manageNeeds]: manageNeedsUrl,
    [projectButtonTypes.allSettings]: manageProjectDashboardUrl,
    [projectButtonTypes.editDescription]: editDescriptionUrl,
    [projectButtonTypes.managePayouts]: managePayoutsUrl,
    [projectButtonTypes.sayThankYou]: sayThankYouUrl,
    [projectButtonTypes.writeNews]: writeNewsUrl,
  }

  return (
    <div className="container px-0">
      <div className="row pb-4">
        <div className="col mt-4">
          <AdminBox variant="dark" customTitle={i18n.t('projects.admin_boxes.header_title')} quickAccessBox withShadow>
            <div className="container">
              <div className={classNames(styles.container, 'flex flex-wrap items-center justify-center')}>
                {quickAccessWirwunderUrl && <QuickAccessButton type="wirwunder" url={quickAccessWirwunderUrl} />}
                {quickAccessNussbaumUrl && <QuickAccessButton type="nussbaum" url={quickAccessNussbaumUrl} />}
                {itemsToDisplay?.map((type) => {
                  return <QuickAccessButton type={type} url={urlsToTypesMap[type] || ''} key={type} />
                })}
                {manageProjectDashboardUrl && <QuickAccessButton type="all_settings" url={manageProjectDashboardUrl} />}
              </div>
            </div>
          </AdminBox>
        </div>
      </div>
    </div>
  )
}
